<template>
  <v-container
    fluid
    class="py-0"
  >
    <v-row
      dense
      class="pa-4"
    >
      <v-col cols="12">
        <v-switch
          :disabled="processing"
          name="auto-password"
          :label="$t('automaticallyGeneratePassword')"
          v-model="autoPassword"
          dense
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          :disabled="processing || autoPassword"
          name="password"
          :label="$t('password')"
          v-model="password"
          filled
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        v-if="!autoPassword"
      >
        <div>
          <v-row
            dense
            justify="space-between"
          >
            <v-col cols="auto">
              {{ $t('passwordStrength') }}
            </v-col><v-col cols="auto">
              {{ passwordStrengthText() }}
            </v-col>
          </v-row>
        </div>
        <div>
          <v-progress-linear
            height="10"
            :color="passwordStrengthColor()"
            v-model="passwordStrength"
            background-color="grey lighten-2"
            :striped="passwordStrength > 109"
          />
        </div>
        <div v-html="passwordMissingText()" />
      </v-col>
    </v-row>
    <v-row class="pa-0">
      <v-col
        cols="12"
        class="px-0 pt-0"
      >
        <v-divider />
      </v-col>
    </v-row>
    <v-row
      justify="space-between"
      dense
      align="center"
    >
      <v-col
        cols="12"
        md="auto"
      >
        <v-row
          class="pa-0"
          dense
        >
          <v-col
            cols="12"
            md="auto"
            class="py-0"
          >
            <v-btn
              block
              large
              color="error"
              text
              :disabled="processing"
              @click="abort"
            >
              {{ $t('abort') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="auto"
            class="py-0"
          >
            <v-btn
              block
              large
              color="error"
              :disabled="processing"
              @click="back"
            >
              {{ $t('previous') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="auto"
      >
        <v-btn
          block
          large
          color="primary"
          :loading="processing"
          :disabled="processing"
          @click="nextStep"
        >
          {{ $t('next') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
	name: "TeamInvitePassword",
	data() {
		return {
			autoPassword: true,
			password: '',
			tempPassword: '',
			passwordStrength: 0
		}
	},
	watch: {
		password() {
			this.passwordStrengthValue()
		},
		autoPassword(value) {
			if(value === true) {
				this.tempPassword = this.password
				this.password = ''
			}
			if(value === false) {
				this.password = this.tempPassword
			}
		}
	},
	computed: {
		processing() {
			return this.$store.state.isProcessingTeamInvite
		},
	},
	methods: {
		abort() {
			this.$emit('close')
		},
		back() {
			this.$emit('back')
		},
		nextStep() {
			this.$emit('next', { password: this.password, autoPassword: this.autoPassword })
		},
		simplePasswordGenerator() {
			let generatedPassword = (Math.random() * Date.now()).toString(36)
			let insertLocations = (Math.random() * Date.now()).toString().split('').slice(0,2)
			let upperCaseLetters = (Math.random() * Date.now()).toString(36).replace(/[0-9\W]/g, '').toUpperCase().split('')
			insertLocations.forEach(loc => {
				generatedPassword = generatedPassword.substr(0, parseInt(loc)) +  upperCaseLetters[parseInt(loc)] + generatedPassword.substr(parseInt(loc))
			})
			if(this.testPassword(generatedPassword)) {
				return generatedPassword
			} else {
				return this.simplePasswordGenerator()
			}
		},
		testPassword(password) {
			if(password.length < 8) {
				return false
			}
			if(!/[A-Z]/.test(password)) {
				return false
			}
			if(!/[a-z]/.test(password)) {
				return false
			}
			if(!/\d/.test(password)) {
				return false
			}
			if(!/\W/.test(password)) {
				return false
			}
			return true
		},
		passwordStrengthColor() {
			if(this.passwordStrength > 150) {
				//return '#BEA8E1'
				return '#921616'
			}
			if(this.passwordStrength > 130) {
				return '#4A9BB2'
			}
			if(this.passwordStrength > 110) {
				return '#0A7979'
			}
			if(this.passwordStrength > 90) {
				return 'success darken-2'
			}
			if(this.passwordStrength > 80) {
				return 'success'
			}
			if(this.passwordStrength > 60) {
				return 'success'
			}
			if(this.passwordStrength >= 30) {
				return 'warning'
			}
			if(this.passwordStrength > 5) {
				return 'error'
			}
			if(this.passwordStrength > 0) {
				return 'error darken-2'
			}
			return ''
		},
		passwordStrengthText() {
			if(this.passwordStrength > 150) {
				return 'GODLIKE'
			}
			if(this.passwordStrength > 130) {
				return 'OMG!!!'
			}
			if(this.passwordStrength > 110) {
				return this.$t('amazing') + '!'
			}
			if(this.passwordStrength > 90) {
				return this.$t('veryStrong') + '!'
			}
			if(this.passwordStrength > 80) {
				return this.$t('strong')
			}
			if(this.passwordStrength > 60) {
				return this.$t('good')
			}
			if(this.passwordStrength >= 30) {
				return this.$t('weak')
			}
			if(this.passwordStrength > 5) {
				return this.$t('veryWeak')
			}
			if(this.passwordStrength > 0) {
				return this.$t('horrible') + '!'
			}
			return ''
		},
		passwordStrengthValue() {
			let score = 0
			if(!this.password) {
				this.passwordStrength = score
			}

			let letters = {}
			for(let i = 0; i < this.password.length; i++) {
				letters[this.password[i]] = (letters[this.password[i]] || 0) + 1
				score += 5.0 / letters[this.password[i]]
			}
			let variations = {
				digits: /\d/.test(this.password),
				lower: /[a-z]/.test(this.password),
				upper: /[A-Z]/.test(this.password),
				nonWords: /\W/.test(this.password),
			}

			let variationCount = 0
			for(let i in variations) {
				variationCount += (variations[i] === true) ? 1 : 0
			}
			score += (variationCount - 1) * 10

			// if(!this.testPassword(this.password) && score >= 60) {
			// 	score = 35
			// }

			this.passwordStrength = score
		},
		passwordMissingText() {
			const passwordNeedsToBe = {
				index: 0,
				text: '<br>'+ this.$t('passwordNeedsToBe') + ':'
			}

			const notEightCharacters = {
				index: 1,
				text: '<br>&bullet; ' + this.$t('atLeast8CharactersLong')
			}

			const noUpperCaseLetter = {
				index: 2,
				text: '<br>&bullet; ' + this.$t('atLeastOneUpperCaseLetter')
			}

			const noLowerCaseLetter = {
				index: 3,
				text: '<br>&bullet; ' + this.$t('atLeastOneLowerCaseLetter')
			}

			const noNumber = {
				index: 4,
				text: '<br>&bullet; ' + this.$t('atLeastOneNumber')
			}

			const noSpecialCharacter = {
				index: 5,
				text: '<br>&bullet; ' + this.$t('atLeastOneSpecialCharacter')
			}

			let text = [passwordNeedsToBe, notEightCharacters, noUpperCaseLetter, noLowerCaseLetter, noNumber, noSpecialCharacter]

			if(this.password.length > 7) {
				text.splice(text.findIndex(t => t.index === 1), 1)
			}
			if(/[A-Z]/.test(this.password)) {
				text.splice(text.findIndex(t => t.index === 2), 1)
			}
			if(/[a-z]/.test(this.password)) {
				text.splice(text.findIndex(t => t.index === 3), 1)
			}
			if(/\d/.test(this.password)) {
				text.splice(text.findIndex(t => t.index === 4), 1)
			}
			if(/\W/.test(this.password)) {
				text.splice(text.findIndex(t => t.index === 5), 1)
			}

			if(text.length > 1) {
				return text.map(t => t.text).join('')
			} else {
				return this.$t('passwordMeetsCriteria')
			}
		},
	}
}
</script>

<style scoped>

</style>
